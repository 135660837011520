export enum MitgliedschaftViewStatsEnum {
  HOME = "Home",
  KONTAKTDATEN = "Kontaktdaten",
  CREATE_STAMMBLATT = "Stammblatt erstellen",
  STAMMBLATT_SUCHE = "Unternehmen suchen",
  RUHEND_WIEDERBETRIEB_MELDUNG = "Ruhendmeldung/Wiederbetriebsmeldung",
  PERSONENBETREUER = "Ruhendmeldung/Wiederbetriebsmeldung für Personenbetreuung",
}

export enum MitgliedschaftPathEnum {
  HOME = "/",
  KONTAKTDATEN = "/kontaktdaten",
  CREATE_STAMMBLATT = "/bestaetigung",
  STAMMBLATT_SUCHE = "/firmenauskunft",
  RUHEND_WIEDERBETRIEB_MELDUNG = "/gewerbeausuebung",
  PERSONENBETREUER = "/personenbetreuer",
}

export enum MitgliedschaftSubPathEnum {
  HOME = "",
  KONTAKTDATEN = "kontaktdaten",
  CREATE_STAMMBLATT = "bestaetigung",
  STAMMBLATT_SUCHE = "firmenauskunft",
  RUHEND_WIEDERBETRIEB_MELDUNG = "gewerbeausuebung",
  PERSONENBETREUER = "personenbetreuer",
}

export enum MitgliedschaftStammblattStatusEnum {
  INITIAL = "Initial",
  STANDORTE_GEWAEHLT = "Standorte gewählt",
  ABGESENDET = "Abgesendet"
}

export enum MitgliedschaftNBWBStatusEnum {
  INITIAL = "Initial",
  BERECHTIGUNGEN_GEWAEHLT = "Berechtigungen gewählt",
  ABGESENDET = "Abgesendet"
}

export enum MitgliedschaftKontaktEditStateEnum {
  UNCHANGED = "Unbearbeitet",
  EDITING = "In Bearbeitung",
  CHANGE_REQUESTED = "Änderungsanfrage"
}

export enum MitgliedschaftKontaktEditingAreaEnum {
  ADDRESS = "Addresse",
  PHONE = "Telefon",
  MOBILE = "Mobil",
  EMAIL = "Email"
}


export enum MESSAGE_TEXTS {
  NBWB_3_WEEKS_MESSAGE_PERS_BETR = "<p>Bitte beachten Sie:</p><p>Die Ruhendmeldung/Wiederaufnahme ist gemäß § 93 der GewO 1994 binnen 3 Wochen der Wirtschaftskammer anzuzeigen." + 
  " Eine Meldung, die nicht innerhalb der gesetzlichen Anzeigepflicht von 3 Wochen gem. § 93 GewO 1994 erfolgt, stellt eine Verwaltungsübertretung dar, " +
  "die von der Gewerbebehörde (Bezirkshauptmannschaft, Magistrat) mit bis zu € 1.090,00 Geldstrafe geahndet werden kann.</p><p>Die rückwirkende Ruhendmeldung " +
  "für die Sozialversicherung ist bis zu 18 Monaten unter folgenden Voraussetzungen möglich (§ 4 Abs 1 Z 1 GSVG):</p><ul><li>Es dürfen keine Leistungen " +
  "aus der Kranken- und Pensionsversicherung in Anspruch genommen worden sein und</li><li>die Tätigkeit zum Wirksamkeitsdatum der Ruhendmeldung " + 
  "nicht ausgeübt worden sein.</li></ul><p>Eine rechtliche Prüfung dieser Voraussetzungen durch die Wirtschaftskammer erfolgt nicht!</p>",
  NBWB_18_MONTH_ERROR = "<p>Bitte beachten Sie:</p><p>Die Ruhendmeldung/Wiederaufnahme ist gemäß § 93 der GewO 1994 binnen 3 Wochen der Wirtschaftskammer anzuzeigen." + 
  " Eine Meldung, die nicht innerhalb der gesetzlichen Anzeigepflicht von 3 Wochen gem. § 93 GewO 1994 erfolgt, stellt eine Verwaltungsübertretung dar, " +
  "die von der Gewerbebehörde (Bezirkshauptmannschaft, Magistrat) mit bis zu € 1.090,00 Geldstrafe geahndet werden kann.</p><p>Die rückwirkende Ruhendmeldung " +
  "für die Sozialversicherung ist bis zu 18 Monaten unter folgenden Voraussetzungen möglich (§ 4 Abs 1 Z 1 GSVG):</p><ul><li>Es dürfen keine Leistungen " +
  "aus der Kranken- und Pensionsversicherung in Anspruch genommen worden sein und</li><li>die Tätigkeit zum Wirksamkeitsdatum der Ruhendmeldung " + 
  "nicht ausgeübt worden sein.</li></ul><p>Bitte kontaktieren Sie Ihre Landeskammer oder korrigieren Sie die Daten!</p>",
  NBWB_180_DAYS_FUTURE_ERROR = " Über dieses Service kann eine Ruhendmeldung bzw. Wiederaufnahme nur bis max. 180 Tage in der Zukunft entgegengenommen werden!",
  NBWB_FILE_SIZE = "Bitte überprüfen Sie die Vollmacht. Minimale Größe 20KB."
}