import { useEffect, useState } from "react";
import { BasicUserInfoResponseType, FoPaketeResponseType } from "../../../api";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import IconArrowRight from "../svgs/IconArrowRight";
import IconMitglied from "../svgs/IconMitglied";
import { Hinweis } from "../view/Hinweise";
import { Warnung } from "../view/Warnung";
import { MitgliedschaftPathEnum } from "../enum/MitgliedschaftViewStatsEnums";
import IconHinweis from "../svgs/IconHinweis";
import { URL_BENUTZERKONTO_IDENTITY, URL_NACHRICHTEN, getLink } from "../constants/urls";

interface Props {
  userInfo?: BasicUserInfoResponseType;
  kontaktdatenEnabled: boolean;
  loginHandler: () => any;
}

export default function MitgliedschaftHome(props: Props) {
  const [foPakete, setFoPakete] = useState<FoPaketeResponseType>();

  const callLogin = (evt: any) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      props.loginHandler();
    }
  };

  useEffect(() => {
    if (props.userInfo && !foPakete) {
      mitgliedschaftService.getFoPakete({}).then((prod) => {
        let data: FoPaketeResponseType = prod.data;
        if (data) {
          setFoPakete(data);
        }
      });
    }
  }, [props.userInfo, foPakete]);

  return (
    <>
      <main data-gtm-block="main">
        <section className="members bg--white" data-gtm-section="Mein WKO - eService Blockchain Datenzertifizierung">
          <div className="container col-lg-7">
            <div className="members-header mb-5">
              <div className="headline">
                <div className="headline-bc">
                  <h1>Mitgliedschaft</h1>
                  <h3 className="subtitle">
                    Melden Sie Ihr Gewerbe online ruhend und erstellen Sie eine Bestätigung Ihrer Mitgliedschaft oder
                    jedes anderen beliebigen WKO-Mitgliedsunternehmen.
                  </h3>
                </div>
                <IconMitglied />
              </div>
              {!props.userInfo && (
                <>
                  <button className="btn btn-primary" type="button" onClick={callLogin}>
                    Gleich loslegen
                  </button>
                </>
              )}
              {false && (
                <Warnung
                  title="+++ Vorankündigung +++"
                  message="Aufgrund von Wartungsarbeiten werden die Services am 28.01.2025 im Zeitraum von 16:00 Uhr bis voraussichtlich 17:00 Uhr nicht zur Verfügung stehen."
                ></Warnung>
              )}
            </div>
            <div className="content">
              <>
                {props.userInfo &&
                  foPakete?.foPaketForFoPaketeBySearchCriteria &&
                  foPakete.foPaketForFoPaketeBySearchCriteria.length > 0 && (
                    <div
                      className="related-links bg--peach-secondary-30 mb-5"
                      data-gtm-box="Verwandte Links"
                      data-gtm-element="verwandtelinks"
                    >
                      <h4>Wir freuen uns Sie begrüßen zu dürfen!</h4>
                      <p>Alle wichtigen Informationen zu Ihrer neuen Fachorganisation finden Sie hier:</p>
                      <ul className="row p-0">
                        {foPakete.foPaketForFoPaketeBySearchCriteria?.map((foPaket, index) => {
                          return (
                            <li key={index} className="col-lg-6 ps-3">
                              <IconArrowRight />
                              {(foPaket.link?.startsWith("<a") && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: foPaket.link || "",
                                  }}
                                ></span>
                              )) || (
                                <>
                                  <a target="_blank" rel="noreferrer" className="btn-link" href={foPaket.link}>
                                    {foPaket.link}
                                  </a>
                                </>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
              </>
              <h4>Ihr Nutzen</h4>
              <p>
                Sie benötigen Ihre Gewerbeberechtigung nicht das ganze Jahr, weil Sie zum Beispiel einen Saisonbetrieb
                führen? Dann ist das Mitgliedschaft eService genau richtig für Sie. Mit diesem Service können Sie als
                Mitglied Ihre Gewerbeberechtigung mit nur wenigen Klicks online ruhend melden bzw. wieder aufnehmen und
                noch weitere Services rund um Ihre Mitgliedschaft nutzen.
              </p>
              <div className="hinweis">
                {props.userInfo &&
                  props.userInfo.authenticatedUserMemberId === "0" &&
                  !props.userInfo.stammblattErsteller && (
                    <>
                      <div
                        className={"alert alert-box alert-info border-0 rounded-0 bg--peach-secondary"}
                        role="alert"
                        data-gtm-box="Hinweis"
                      >
                        <div className="alert-info-bulb">
                          <IconHinweis />
                          <span>Hinweis</span>
                        </div>
                        <div className="alert-info-content">
                          <p className="title">Noch kein Unternehmen zugeordnet?</p>
                          <p>
                            Um alle Services rund um Ihre Mitgliedschaft nutzen zu können,{" "}
                            <a href={getLink(URL_BENUTZERKONTO_IDENTITY)}>
                              ordnen Sie jetzt ein Unternehmen Ihrem Benutzerkonto zu.
                            </a>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                {(!props.userInfo || props.userInfo.authenticatedUserMemberId !== "0") && (
                  <>
                    {props.kontaktdatenEnabled && (
                      <Hinweis
                        title="Kontaktdaten"
                        link={MitgliedschaftPathEnum.KONTAKTDATEN}
                        userInfo={props.userInfo}
                        message="Digitale und postalische Zusendeadresse verwalten, sowie einen Überblick über weitere hinterlegte Kontaktinformationen aus Firmen A-Z und Benutzerkonto erhalten."
                      />
                    )}
                    <Hinweis
                      title="Bestätigung"
                      link={MitgliedschaftPathEnum.CREATE_STAMMBLATT}
                      userInfo={props.userInfo}
                      message="Holen Sie sich wichtige Kennzahlen und Informationen zu Ihren aufrechten Mitgliedschaften!"
                    />
                  </>
                )}
                {(!props.userInfo ||
                  props.userInfo.authenticatedUserMemberId !== "0" ||
                  props.userInfo.stammblattErsteller) && (
                  <Hinweis
                    title="Firmenauskunft"
                    link={MitgliedschaftPathEnum.STAMMBLATT_SUCHE}
                    userInfo={props.userInfo}
                    message="Informieren Sie sich über andere Unternehmen und beziehen Sie wichtige Details"
                  />
                )}
                {(!props.userInfo || props.userInfo?.authenticatedUserMemberId !== "0") && (
                  <Hinweis
                    title="Gewerbeausübung"
                    link={MitgliedschaftPathEnum.RUHEND_WIEDERBETRIEB_MELDUNG}
                    userInfo={props.userInfo}
                    message="Ganz ohne Behördenwege – Mitgliedschaften bequem ruhend melden oder wiederaufnehmen!"
                  />
                )}
                {(!props.userInfo || props.userInfo?.personenbetreuer) && (
                  <Hinweis
                    title="Personenbetreuung"
                    link={MitgliedschaftPathEnum.PERSONENBETREUER}
                    userInfo={props.userInfo}
                    message="Ein Service nur für Mitglieder der Organisation für Personenbetreuung"
                  />
                )}
              </div>
              <p>
                Hier können Sie bequem und einfach Ihre Mitglieds- und Gewerbebestätigung zur Vorlage bei Ämtern und
                Behörden anfordern und ausdrucken.{" "}
              </p>
              <p>
                Die Dokumente werden zusätzlich unter <a href={getLink(URL_NACHRICHTEN)}>„Mein Postfach“</a> für Sie
                bereitgestellt. Dort können Sie diese auch später erneut ausdrucken.{" "}
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
